
import Vue from "vue";

import http from "@/http";

import { MailLog } from "@/interfaces";

import MyMailLogs from "@/components/cancel/MyMailLogs.vue";

export interface DataType {
  mailLogs: MailLog[];
  query: {
    email: string;
  };
}

export default Vue.extend({
  components: {
    MyMailLogs,
  },
  data(): DataType {
    return {
      mailLogs: [],
      query: {
        email: "",
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "メールログ",
          disabled: false,
          to: "/maillogs",
        },
        {
          text: this.query.email,
          disabled: true,
        },
      ];
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { email } = this.$route.query;

      if (email) {
        this.query.email = email.toString();
      } else {
        return;
      }

      const url = `mail_logs`;

      const { data } = await http.get<MailLog[]>(url, {
        params: {
          email,
        },
      });

      this.mailLogs = data;
    },
  },
});
