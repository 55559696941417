
import Vue, { PropType } from "vue";

import { MailLog } from "@/interfaces";
import { dateTime } from "@/filters";

import { mdiEmail, mdiEmailOpen } from "@mdi/js";

import dayjs from "dayjs";

export default Vue.extend({
  filters: {
    dateTime,
  },
  props: {
    mailLogs: {
      type: Array as PropType<MailLog[]>,
      required: true,
    },
  },
  computed: {
    icon() {
      return { mdiEmail, mdiEmailOpen };
    },
  },
});
